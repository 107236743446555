import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from '../assets/img/d2i-technology-logo.png'

export default () => {
  return (
    <Layout>
      <SEO
        title="Schedule a Call"
        description="Schedule a call with D2i Technology to discuss career opportunities and join our innovative team. Take the next step in shaping the future of technology with us."
        keywords={['Schedule a Call', 'Book a Consultation', 'D2i Technology Meeting', 'Talk to Our Experts']}
      />
      <main id="main">
        <section id="schedule-call" className="schedule-call section-bg">
          <div className="container" data-aos="fade-up">
            <header className="section-header text-center">
              <h1>Schedule a Call with D2i Technology</h1>
            </header>
            <div className="text-center">
            <img id="logoImage" src={logo} alt="D2i Technology - Software development company logo" style={ {width: "11rem", paddingTop: "2rem", paddingBottom: "1rem" } } />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <iframe
                  src="https://calendly.com/ashish-d2itechnology/d2itechnology"
                  width="100%"
                  height="700"
                  frameBorder="0"
                  title="Schedule a Call with D2i Technology"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}